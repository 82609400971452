.fulllogo {
  max-width:30%;
  display:block;
  max-height:50vh;
  margin:0 auto;
  margin-top:10vh;

}

button {
  color: #450080;
}

.abcRioButtonBlue .abcRioButtonContentWrapper {
  border: 1px solid transparent;
}

.abcRioButton.abcRioButtonBlue {
  background-color: #4285f4;
  border: none;
  color: #fff;
}

.abcRioButton {
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
  box-sizing: border-box;
  -webkit-transition: background-color .218s,border-color .218s,box-shadow .218s;
  transition: background-color .218s,border-color .218s,box-shadow .218s;
  -webkit-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  color: #262626;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  margin: 0 auto;
  margin-top:100px;
}

.abcRioButtonBlue .abcRioButtonIcon {
  background-color: #fff;
  border-radius: 1px;
}
.abcRioButtonIcon {
  float: left;
}

.abcRioButtonContents {
  font-family: Roboto,arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .21px;
  margin-left: 6px;
  margin-right: 6px;
  vertical-align: top;
}

.abcRioButtonContentWrapper {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'BenchNine', sans-serif;

}

.header {
  height: 50px;
  width:100%;
box-shadow: 0px 5px 1px 1px rgba(204,204,204,1);
  padding: 10px;
  position:relative;
}

.header-logo-component {
  height: 100%;
  padding-right:30px;
}

.header-logo-component.texthorizontal {
  height:70%;
  margin: 0 auto;
  width:100%;
  position: absolute;
  display:block;
  top:10px;
}


.header-logo-component.texthorizontal img {
  height:100%;
  margin: 0 auto;
  display:block;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.action-button {
  font-size: 20px;
  line-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  -webkit-transition: all 1s ease;
  transition: all 1s ease; 
  position:fixed;
  left: 20px;
  bottom:30px;
  z-index:99999;
}

/* add action stuff */
.add-action-button {
  background-color:#450080;
  font-size:45px;
  opacity: 1;
}

/* add action stuff */
.edit-action-button {
  color: #071013;
  background-color:#23B5D3;
  opacity: 0;
}

/* add action stuff */
.cancel-action-button {
  color: #071013;
  background-color:#75ABBC;
  opacity: 0;
}

.action-button.activated {
  bottom: 78%;

}

.edit-action-button.activated{
  opacity: 1;

}.cancel-action-button.activated{
  left: 100px;
  opacity: 1;

}

.todo-app {
  overflow: hidden;
  position:relative;
}

.action-form {
  visibility: hidden;
  padding: 10px;
  padding-top: 30px;

  height: 76%;
  position: fixed;
  bottom: -100%;
  -webkit-transition: all 1s ease;
  transition: all 1s ease; 
  left: 0px;
  z-index:9999;
  background-color:#450080;
  color: #fff;

  width:100%;
}
.action-form label {
  padding:10px;
  width: 40%;
  font-size: 1.2em;
  font-weight: 700;
  display:inline-block;
}

.action-form input {
  width: 40%;
}

.action-form .ingredient {
  padding: 1%;
  margin: 1%;

  background-color: rgba(0, 0, 0, 0.25);
}

.action-form .ingredient label {
  padding-top: 0px;
}

.action-form .panel, .recipe-container .panel {
  padding:1%;
  width: 30%;
  height: 90%;
  overflow: auto;
  display: inline-block;
}

.recipe-container .panel {
  height: 80%;
}



.recipe-container .edit-button {
  float: right;
}

.recipe-container h1 {
  margin: 0 auto;
}


.action-form.activated {
  bottom: 0px;
  visibility:visible;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Recipes */
.recipe-container {
  width: 96%;
  height:100px;
  padding: 20px;
  margin: 1%;
  box-shadow: 1px 5px 14px 2px rgba(204,204,204,1);
}

